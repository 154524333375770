import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useApi} from "../../providers/ApiProvider";
import {useParams} from "react-router-dom";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import {useNotifications} from "../../providers/NotificationsProvider";
import CategoriesModel from "../../models/CategoriesModel";

export default function CategoryEdit(props) {
    const params = useParams();
    const apiContext = useApi();
    const categoriesModel = new CategoriesModel(apiContext.api);
    const notificationsContext = useNotifications();

    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [formParentId, setFormParentId] = React.useState('');
    const [formType, setFormType] = React.useState('');
    const [formTitle, setFormTitle] = React.useState('');
    const [formIcon, setFormIcon] = React.useState('');
    const [formOrder, setFormOrder] = React.useState(0);
    const [formAliases, setFormAliases] = React.useState('');

    const [categories, setCategories] = React.useState({});


    useEffect(() => {
        document.title = 'Редагувати категорію | Nascho';

        categoriesModel.view(params.categoryId).then((response) => {
            if (response !== false) {
                setFormTitle(response.title);
                setFormParentId(response.parent_id);
                setFormType(response.type);
                setFormIcon(response.icon);
                setFormOrder(response.order);
                setFormAliases(response.aliases);
            }
        });

        categoriesModel.list().then((response) => {
            if (response !== false) {
                setCategories(response);
            }
        });
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        categoriesModel.update(params.categoryId, {
            title: formTitle,
            parent_id: formParentId,
            type: formType,
            icon: formIcon,
            order: formOrder,
            aliases: formAliases,
        }, onUpdateError).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Категорія оновлена!', 'success');
            }

            setLoading(false);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
    }


    const getCategoriesTypeLabel = (type) => {
        switch (type) {
            case 'expense':
                return 'Витрати';
            case 'income':
                return 'Доходи';
            case 'transfer':
                return 'Переміщення';
            default:
                return 'Інше';
        }
    }


    return (
        <SimpleCardContainer title={'Редагувати інформацію про категорію'}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'row'}>
                            <div className={'col-md-5'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Назва</label>

                                    <input id={'cd-id'} className={'form-control'} value={formTitle} onChange={(e) => {
                                        setFormTitle(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.title && <div className="form-text text-danger">{errors.title.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-3'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Тип категорії</label>

                                    <select id={'cd-id'} className={'form-select'} value={formType} onChange={(e) => {
                                        setFormParentId(e.target.value)
                                    }}>
                                        {['income', 'expense', 'transfer'].map((item, index) => {
                                            return (
                                                <option value={item} key={index}>{getCategoriesTypeLabel(item)}</option>
                                            );
                                        })}
                                    </select>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.type && <div className="form-text text-danger">{errors.type.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-4'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Батьківська категорія</label>

                                    <select id={'cd-id'} className={'form-select'} value={formParentId} onChange={(e) => {
                                        setFormParentId(e.target.value)
                                    }}>
                                        <option value={''}>Без батьківської категорії</option>
                                        {Object.keys(categories).length && Object.keys(categories).map((categoriesType, tIndex) => {
                                            return (
                                                <optgroup label={getCategoriesTypeLabel(categoriesType)} key={tIndex}>
                                                    {categories[categoriesType].map((item, index) => {
                                                        return (
                                                            <option value={item.id}
                                                                    key={tIndex + '|' + index}>{item.title} {item.id === parseInt(params.categoryId) ? '❌❌❌' : ''}</option>
                                                        );
                                                    })}
                                                </optgroup>
                                            );
                                        })}
                                    </select>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.parent_id && <div className="form-text text-danger">{errors.parent_id.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-md-4'}>
                                <div className={'form-group mt-3'}>
                                    <label className="form-label fw-semibold mb-2">Іконка</label>

                                    <input id={'cd-id'} className={'form-control'} value={formIcon} onChange={(e) => {
                                        setFormIcon(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.icon && <div className="form-text text-danger">{errors.icon.join(' ')}</div>}
                                        <div className="fs-2 text-end flex-grow-1">Клас ti-*, наприклад ti-message</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-4'}>
                                <div className={'form-group mt-3'}>
                                    <label className="form-label fw-semibold mb-2">Порядок сортування</label>

                                    <input id={'cd-id'} className={'form-control'} value={formOrder} onChange={(e) => {
                                        setFormOrder(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.order && <div className="form-text text-danger">{errors.order.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'form-group mt-3'}>
                            <label className="form-label fw-semibold mb-2">Аліаси</label>

                            <input id={'cd-id'} className={'form-control'} value={formAliases} onChange={(e) => {
                                setFormAliases(e.target.value)
                            }}/>

                            <div className="d-flex justify-content-between">
                                {errors && errors.aliases && <div className="form-text text-danger">{errors.aliases.join(' ')}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'form-group mt-4'}>
                    <ButtonWithLoading
                        className={'btn-primary'}
                        noOriginalIconClassName={true}
                        iconClassName={'ti ti-device-floppy fs-5 me-2'}
                        label={'Зберегти зміни'}
                        loading={loading}
                        onClick={onSubmit}
                    />
                </div>
            </form>
        </SimpleCardContainer>
    );
}
