/**
 * Rules model
 *
 * @param {ApiHelper} api
 */
export default class RulesModel {
    baseUrl = '/rules';


    static operators = [
        {'value': 'eq', 'label': 'Дорівнює'},
        {'value': 'ne', 'label': 'Не дорівнює'},
        {'value': 'gt', 'label': 'Більше'},
        {'value': 'lt', 'label': 'Менше'},
        {'value': 'gte', 'label': 'Більше чи дорівнює'},
        {'value': 'lte', 'label': 'Менше чи дорівнює'},
        {'value': 'like', 'label': 'Містить'},
        {'value': 'not_like', 'label': 'Не містить'},
        {'value': 'starts_with', 'label': 'Починається з'},
        {'value': 'ends_with', 'label': 'Закінчується на'},
        {'value': 'regexp', 'label': 'Регулярний вираз'}
    ];
    static fields = [
        {'value': 'category', 'label': 'Категорія'},
        {'value': 'account', 'label': 'Рахунок'},
        {'value': 'description', 'label': 'Інформація про транзакцію'},
        {'value': 'user_notes', 'label': 'Користувацькі нотатки'},
        {'value': 'paid_amount', 'label': 'Сума витрати'},
        {'value': 'paid_currency', 'label': 'Валюта витрати'},
        {'value': 'received_amount', 'label': 'Сума приходу'},
        {'value': 'received_currency', 'label': 'Валюта приходу'},
    ];

    static filteredOperators = {
        'category': ['eq', 'ne', 'like', 'not_like', 'starts_with', 'ends_with', 'regexp'],
        'account': ['eq', 'ne', 'like', 'not_like', 'starts_with', 'ends_with', 'regexp'],
        'description': ['eq', 'ne', 'like', 'not_like', 'starts_with', 'ends_with', 'regexp'],
        'user_notes': ['eq', 'ne', 'like', 'not_like', 'starts_with', 'ends_with', 'regexp'],
        'paid_amount': ['eq', 'ne', 'gt', 'lt', 'gte', 'lte', 'regexp'],
        'paid_currency': ['eq', 'ne', 'like', 'not_like', 'starts_with', 'ends_with', 'regexp'],
        'received_amount': ['eq', 'ne', 'gt', 'lt', 'gte', 'lte', 'regexp'],
        'received_currency': ['eq', 'ne', 'like', 'not_like', 'starts_with', 'ends_with', 'regexp'],
    };


    constructor(api) {
        this.api = api;
    }


    async list(filter = null, sorting =  null, errorHandler) {
        let url = this.baseUrl;
        let query = {};
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async view(pk, errorHandler) {
        const url = this.baseUrl + '/' + pk;

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async create(data, errorHandler) {
        const url = this.baseUrl;

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async update(pk, data, errorHandler) {
        const url = this.baseUrl + '/' + pk;

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async process(pk, errorHandler) {
        const url = this.baseUrl + '/' + pk + '/process';

        const response = await this.api.postRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async delete(pk, errorHandler) {
        const url = this.baseUrl + '/' + pk;

        const response = await this.api.deleteRequest(url);

        return this.processResponse(response, errorHandler);
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}