import React from "react";
import CardLoading from "./CardLoading";

function SimpleCardContainer({title, titleRight, loading, children}) {
    return (
        <div className={'card w-100 position-relative '+(loading ? ' loading' : '')}>
            <div className="px-4 py-3 border-bottom d-flex align-items-center justify-content-between">
                <h5 className="align-items-center card-title d-flex fw-semibold gap-2 lh-sm mb-0">{title}</h5>

                {titleRight && (
                    <div className="d-flex align-items-center">
                        {titleRight}
                    </div>
                )}
            </div>
            <div className="card-body p-4">
                {children}
            </div>

            <CardLoading loading={loading} />
        </div>
    )
}

export default SimpleCardContainer;