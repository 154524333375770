/**
 * Tags model
 *
 * @param {ApiHelper} api
 */
export default class TagsModel {
    baseUrl = '/tags';

    static styles = {
        'red': {value: 'red', color: '#D32F2F', label: 'Червоний'},
        'pink': {value: 'pink', color: '#C2185B', label: 'Рожевий'},
        'purple': {value: 'purple', color: '#7B1FA2', label: 'Пурпурний'},
        'violet': {value: 'violet', color: '#512DA8', label: 'Фіолетовий'},
        'indigo': {value: 'indigo', color: '#303F9F', label: 'Індіго'},
        'blue': {value: 'blue', color: '#1976D2', label: 'Синій'},
        'light-blue': {value: 'light-blue', color: '#0288D1', label: 'Блакитний'},
        'cyan': {value: 'cyan', color: '#0097A7', label: 'Бірюзовий'},
        'teal': {value: 'teal', color: '#00796B', label: 'Темно-зелений'},
        'green': {value: 'green', color: '#388E3C', label: 'Зелений'},
        'light-green': {value: 'light-green', color: '#689F38', label: 'Світло-зелений'},
        'lime': {value: 'lime', color: '#AFB42B', label: 'Лаймовий'},
        'yellow': {value: 'yellow', color: '#FBC02D', label: 'Жовтий'},
        'amber': {value: 'amber', color: '#FFA000', label: 'Бурштиновий'},
        'orange': {value: 'orange', color: '#F57C00', label: 'Помаранчевий'},
        'deep-orange': {value: 'deep-orange', color: '#E64A19', label: 'Темно-помаранчевий'},
        'brown': {value: 'brown', color: '#5D4037', label: 'Коричневий'},
        'gray': {value: 'gray', color: '#616161', label: 'Сірий'},
        'blue-gray': {value: 'blue-gray', color: '#455A64', label: 'Синьо-сірий'}
    };

    constructor(api) {
        this.api = api;
    }


    async list(filter = null, sorting =  null, errorHandler) {
        let url = this.baseUrl;
        let query = {};
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async listPlain(errorHandler) {
        const url = this.baseUrl + '/plain';

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async view(pk, errorHandler) {
        const url = this.baseUrl + '/' + pk;

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async create(data, errorHandler) {
        const url = this.baseUrl;

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async update(pk, data, errorHandler) {
        const url = this.baseUrl + '/' + pk;

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async delete(pk, errorHandler) {
        const url = this.baseUrl + '/' + pk;

        const response = await this.api.deleteRequest(url);

        return this.processResponse(response, errorHandler);
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}