import React from 'react';
import TagsModel from "../../models/TagsModel";
import {SelectDot} from "../layout/ui/SelectStyles";
import {styled} from '@stitches/react';


export default function TagBadge({tag, className = ''}) {
    const color = TagsModel.styles[tag.style] ? TagsModel.styles[tag.style].color : '#333333';
    let dotStyle = SelectDot(color);
    dotStyle['&:before']['width'] = '7px';
    dotStyle['&:before']['height'] = '7px';
    const StyledSpan = styled('span', dotStyle);

    return (
        <StyledSpan className={className}>
            {tag.name}
        </StyledSpan>
    );
}