/**
 * Categories model
 *
 * @param {ApiHelper} api
 */
export default class CategoriesModel {
    constructor(api) {
        this.api = api;
    }


    async list(errorHandler) {
        const url = '/categories';

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async view(pk, errorHandler) {
        const url = '/categories/' + pk;

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async create(data, errorHandler) {
        const url = '/categories/create';

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async update(pk, data, errorHandler) {
        const url = '/categories/' + pk;

        const response = await this.api.postRequest(url, data);

        return this.processResponse(response, errorHandler);
    }


    async delete(pk, errorHandler) {
        const url = '/categories/' + pk + '/delete';

        const response = await this.api.deleteRequest(url);

        return this.processResponse(response, errorHandler);
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}