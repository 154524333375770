import {createBrowserRouter} from "react-router-dom";
import App from "./App";
import {RequireAuth} from "./providers/AuthProvider";
import Home from "./components/common/Home";
import Auth from "./components/auth/Auth";
import Login from "./components/auth/Login";
import React from "react";
import ForgotPassword from "./components/auth/ForgotPassword";
import Error404 from "./components/errors/Error404";
import Logout from "./components/auth/Logout";
import MainLayout from "./components/layout/MainLayout";
import Faq from "./components/common/Faq";
import ForgotPasswordConfirm from "./components/auth/ForgotPasswordConfirm";
import Register from "./components/auth/Register";
import Transactions from "./components/transactions/Transactions";
import TransactionsList from "./components/transactions/TransactionsList";
import TransactionsImport from "./components/transactions/TransactionsImport";
import Categories from "./components/categories/Categories";
import CategoriesList from "./components/categories/CategoriesList";
import CategoryEdit from "./components/categories/CategoryEdit";
import Tags from "./components/tags/Tags";
import TagsEdit from "./components/tags/TagsEdit";
import TagsList from "./components/tags/TagsList";
import TagsCreate from "./components/tags/TagsCreate";
import Rules from "./components/rules/Rules";
import RulesList from "./components/rules/RulesList";
import RulesCreate from "./components/rules/RulesCreate";
import RulesEdit from "./components/rules/RulesEdit";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: App,
        children: [
            {
                index: true,
                //loader: homeLoader,
                element: (<RequireAuth><MainLayout><Home/></MainLayout></RequireAuth>),
            },
            {
                path: "auth",
                Component: Auth,
                children: [
                    {
                        path: "login",
                        Component: Login,
                    },
                    {
                        path: "register",
                        Component: Register,
                    },
                    {
                        path: "forgot-password",
                        children: [
                            {
                                path: "confirm",
                                Component: ForgotPasswordConfirm,
                            },
                            {
                                index: true,
                                Component: ForgotPassword,
                            }
                        ],
                    },
                    {
                        path: "logout",
                        Component: Logout,
                    },
                ],
            },
            {
                path: "t",
                element: (<RequireAuth><MainLayout><Transactions /></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "import",
                        Component: TransactionsImport,
                    },
                    {
                        index: true,
                        Component: TransactionsList,
                    },
                ],
            },
            {
                path: "categories",
                element: (<RequireAuth><MainLayout><Categories /></MainLayout></RequireAuth>),
                children: [
                    /*{
                        path: "create",
                        Component: CategoryCreate,
                    },*/
                    {
                        path: ":categoryId/edit",
                        Component: CategoryEdit,
                    },
                    {
                        index: true,
                        Component: CategoriesList,
                    },
                ],
            },
            {
                path: "tags",
                element: (<RequireAuth><MainLayout><Tags /></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "create",
                        Component: TagsCreate,
                    },
                    {
                        path: ":tagId",
                        Component: TagsEdit,
                    },
                    {
                        index: true,
                        Component: TagsList,
                    },
                ],
            },
            {
                path: "rules",
                element: (<RequireAuth><MainLayout><Rules /></MainLayout></RequireAuth>),
                children: [
                    {
                        path: "create",
                        Component: RulesCreate,
                    },
                    {
                        path: ":ruleId",
                        Component: RulesEdit,
                    },
                    {
                        index: true,
                        Component: RulesList,
                    },
                ],
            },
            {
                path: "faq",
                element: (<RequireAuth><MainLayout><Faq /></MainLayout></RequireAuth>),
            },
        ],
    },
    {
        path: "*",
        Component: Error404,
    }
]);
