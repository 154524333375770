import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import TagsModel from "../../models/TagsModel";
import TagsSearchForm from "./TagsSearchForm";
import {Link} from "react-router-dom";
import TagBadge from "./TagBadge";

export default function TagsList(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const tagsModel = new TagsModel(apiContext.api);


    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'name', direction: 'asc'}
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        tagsModel.list(filter.searchText, filter.sorting, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Помилка при завантаженні', 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Теги до транзакцій | Nascho';

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        if (!search && !sort && !direction) {
            loadData();
        } else {
            setFilter(newFilter);
        }
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/tags';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            query.sort = filter.sorting.field;
            query.direction = filter.sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const onDelete = (tagId) => {
        if (window.confirm('Ви впевнені, що хочете видалити цей тег та пов\'язані з ним правила?')) {
            tagsModel.delete(tagId).then((response) => {
                if (response !== false) {
                    notifications.notify('Тег видалено!', 'success');
                    loadData();
                }
            });
        }
    }


    return (
        <>
            <SimpleCardContainer
                title={'Теги до транзакцій'}
                loading={loading}
            >
                <div className="d-flex justify-content-between pt-2 pb-4">
                    <Link to={'/tags/create'} className={'btn btn-success text-white'}>Додати новий тег</Link>

                    <div className="flex-grow-1">
                        <TagsSearchForm onSearch={onSearch}/>
                    </div>
                </div>

                <div className="table-responsive rounded-2">
                    <table className="table border customize-table mb-0 align-middle">
                        <thead className="text-dark fs-4">
                        <tr>
                            <th style={{width: '240px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'name') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="name" onClick={onSort}>
                                    Назва
                                    {(filter.sorting.field === 'name') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'name') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'rules') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="rules" onClick={onSort}>
                                    Правила
                                    {(filter.sorting.field === 'rules') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'rules') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td><TagBadge tag={item} /></td>
                                    <td className="fs-2">{item.rules.map((rule, index, rules) => {
                                        return (
                                            <span key={index} className={'d-block ' + ((index === (rules.length - 1)) ? ('me-2') : (''))}>{rule}</span>
                                        );
                                    })}</td>
                                    <td style={{width: '180px'}}>
                                        <div className="d-flex align-items-center justify-content-end gap-3">
                                            <Link to={'/tags/'+item.id} className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}>
                                                <i className="fs-5 ti ti-edit text-primary"></i>
                                            </Link>
                                            <button type="button"
                                                    className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onDelete(item.id)
                                                    }}>
                                                <i className="fs-5 ti ti-trash text-danger"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </SimpleCardContainer>
        </>
    );
}