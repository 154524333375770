import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import Select from "react-select";
import {useApi} from "../../providers/ApiProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import {useNotifications} from "../../providers/NotificationsProvider";
import RulesModel from "../../models/RulesModel";
import FormCheckbox from "../layout/ui/FormCheckbox";
import {SelectStyles} from "../layout/ui/SelectStyles";
import TagsModel from "../../models/TagsModel";

export default function RulesEdit(props) {
    const apiContext = useApi();
    const params = useParams();
    const tagsModel = new TagsModel(apiContext.api);
    const rulesModel = new RulesModel(apiContext.api);
    const notificationsContext = useNotifications();

    const [availableOperators, setAvailableOperators] = React.useState(RulesModel.operators);
    const [tags, setTags] = React.useState([]);
    const [redirectToRule, setRedirectToRule] = React.useState(false);
    const [processLoading, setProcessLoading] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState([]);
    const [formField, setFormField] = React.useState('');
    const [formOperator, setFormOperator] = React.useState('');
    const [formValue, setFormValue] = React.useState('');
    const [formTag, setFormTag] = React.useState('');
    const [formIsActive, setFormIsActive] = React.useState(true);


    useEffect(() => {
        document.title = 'Редагувати правило | Nascho';

        tagsModel.listPlain().then((response) => {
            if (response !== false) {
                let tagsFormatted = [];
                response.forEach((tag) => {
                    tagsFormatted.push({
                        value: tag.id,
                        label: tag.name,
                        color: TagsModel.styles[tag.style] ? TagsModel.styles[tag.style].color : '#000000'
                    });
                });

                setTags(tagsFormatted);

                rulesModel.view(params.ruleId, onUpdateError).then((response) => {
                    if (response !== false) {
                        setFormField(response.field);
                        setFormOperator(response.operator);
                        setFormValue(response.value);
                        setFormTag(tagsFormatted.find(tag => tag.value === response.tag_id));
                        setFormIsActive(response.is_active);
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }
        });
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        rulesModel.update(params.ruleId, {
            field: formField,
            operator: formOperator,
            value: formValue,
            tag_id: formTag.value,
            is_active: formIsActive
        }, onUpdateError).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Правило оновлено!', 'success');

                setRedirectToRule(response.id);
            }

            setLoading(false);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
    }


    const onFieldChange = (e) => {
        setFormField(e.target.value);
    }


    useEffect(() => {
        let filteredOperators = RulesModel.filteredOperators[formField] || [];
        setAvailableOperators(filteredOperators);

        if (formOperator && !filteredOperators.includes(formOperator)) {
            setFormOperator('');
        }
    }, [formField]);


    const onTagChange = (selectedOption) => {
        if (!selectedOption) {
            return;
        }

        if (!formTag || selectedOption.value !== formTag.value) {
            setFormTag(tags.find(tag => tag.value === selectedOption.value));
        }
    }


    const onProcessClick = (e) => {
        e.preventDefault();
        setProcessLoading(params.ruleId);

        rulesModel.process(params.ruleId, onUpdateError).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Транзакції оброблено!', 'success');
            }
        }).finally(() => {
            setProcessLoading(false);
        });
    }


    return (<>
        {redirectToRule && (<Navigate to={'/rules/' + redirectToRule}/>)}

        <div className="d-flex m-block-gap">
            <Link to={'/rules'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                Повернутися до списку правил
            </Link>
        </div>

        <div className={'row'}>
            <div className={'col-md-8'}>
                <SimpleCardContainer title={'Редагувати правило'} loading={loading}>
                    <form className={'py-2'}>
                        <div className={'row mb-3'}>
                            <div className={'col-md-4'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Поле</label>

                                    <select className="form-select" value={formField} onChange={onFieldChange}>
                                        <option value={''}>Виберіть...</option>
                                        {RulesModel.fields.map((field, index) => {
                                            return (
                                                <option key={index} value={field.value}>{field.label}</option>
                                            );
                                        })}
                                    </select>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.field && <div className="form-text text-danger">{errors.field.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-3'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Оператор</label>

                                    <select className="form-select" value={formOperator} onChange={(e) => {
                                        setFormOperator(e.target.value)
                                    }}>
                                        <option value={''}>Виберіть...</option>
                                        {RulesModel.operators.filter((operator) => availableOperators.includes(operator.value)).map((operator, index) => {
                                            return (
                                                <option key={index} value={operator.value}>{operator.label}</option>
                                            );
                                        })}
                                    </select>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.operator && <div className="form-text text-danger">{errors.operator.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-5'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Значення</label>

                                    <input id={'cd-id'} className={'form-control'} value={formValue} onChange={(e) => {
                                        setFormValue(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.value && <div className="form-text text-danger">{errors.value.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row mb-3'}>
                            <div className={'col-md-4'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Тег</label>

                                    <Select
                                        name="tag"
                                        classNamePrefix="ps-"
                                        value={formTag}
                                        options={tags}
                                        onChange={onTagChange}
                                        isSearchable={false}
                                        isMulti={false}
                                        styles={SelectStyles}
                                    />

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.tag_id && <div className="form-text text-danger">{errors.tag_id.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FormCheckbox
                            label={'Активний'}
                            name={'is_active'}
                            onChange={setFormIsActive}
                            value={formIsActive}
                        />

                        <div className={'form-group mt-3'}>
                            <ButtonWithLoading
                                className={'btn-primary'}
                                noOriginalIconClassName={true}
                                iconClassName={'ti ti-device-floppy fs-5 me-2'}
                                label={'Зберегти зміни'}
                                loading={loading}
                                onClick={onSubmit}
                            />
                        </div>
                    </form>
                </SimpleCardContainer>
            </div>
            <div className={'col-md-4'}>
                <SimpleCardContainer title={'Додаткові операції'} loading={loading}>
                    <p>Ви можете використати це правило для поточних транзакцій.</p>

                    <ButtonWithLoading
                        className={'btn-light-success text-success'}
                        noOriginalIconClassName={true}
                        iconClassName={'ti ti-refresh fs-5 me-2'}
                        label={'Опрацювати транзакції'}
                        loading={processLoading}
                        onClick={onProcessClick}
                    />
                </SimpleCardContainer>
            </div>
        </div>
    </>);
}
