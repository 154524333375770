import React, {useEffect, useState} from 'react';
import {useAuth} from "../../providers/AuthProvider";
import {Navigate} from "react-router-dom";

const Logout = (props) => {
    document.title = 'Logout | Nascho';

    const authContext = useAuth();
    const [unauthorized, setUnauthorized] = useState(false);

    useEffect(() => {
        authContext.logout();

        setUnauthorized(true);
    }, []);

    return (
        <>
            <div className="text-center">
                <h4 className="mb-0">Thank you!</h4>
            </div>

            {unauthorized && (
                <Navigate to="/auth/login" replace={true} />
            )}
        </>
    );
}

export default Logout;
