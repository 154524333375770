import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import RulesModel from "../../models/RulesModel";
import RulesSearchForm from "./RulesSearchForm";
import {Link} from "react-router-dom";
import TagBadge from "./../tags/TagBadge";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";

export default function RulesList(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const rulesModel = new RulesModel(apiContext.api);

    const [processLoading, setProcessLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'id', direction: 'asc'}
    });
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        rulesModel.list(filter.searchText, filter.sorting, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Помилка при завантаженні', 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Правила обробки транзакцій | Nascho';

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        if (!search && !sort && !direction) {
            loadData();
        } else {
            setFilter(newFilter);
        }
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/rules';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            query.sort = filter.sorting.field;
            query.direction = filter.sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const onDelete = (tagId) => {
        if (window.confirm('Ви впевнені, що хочете видалити це правило?')) {
            rulesModel.delete(tagId).then((response) => {
                if (response !== false) {
                    notifications.notify('Правило видалено!', 'success');
                    loadData();
                }
            });
        }
    }


    const onProcess = (ruleId) => {
        setProcessLoading(ruleId);

        rulesModel.process(ruleId).then((response) => {
            if (response !== false) {
                notifications.notify('Транзакції оброблено!', 'success');
            }
        }).finally(() => {
            setProcessLoading(false);
        });
    }


    const onProcessAll = () => {
        setProcessLoading('all');

        rulesModel.process('all').then((response) => {
            if (response !== false) {
                notifications.notify('Транзакції оброблено!', 'success');
            }
        }).finally(() => {
            setProcessLoading(false);
        });
    }


    return (
        <>
            <SimpleCardContainer
                title={'Правила обробки транзакцій'}
                loading={loading}
            >
                <div className="d-flex justify-content-between pt-2 pb-4">
                    <Link to={'/rules/create'} className={'btn btn-success text-white'}>Додати нове правило</Link>

                    <div className="flex-grow-1">
                        <RulesSearchForm onSearch={onSearch}/>
                    </div>
                </div>

                <div className="table-responsive rounded-2">
                    <table className="table border customize-table mb-0 align-middle">
                        <thead className="text-dark fs-4">
                        <tr>
                            <th>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'id') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="id" onClick={onSort}>
                                    Правило
                                    {(filter.sorting.field === 'id') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'id') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th style={{width: '300px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'tag') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="tag" onClick={onSort}>
                                    Тег
                                    {(filter.sorting.field === 'tag') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'tag') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{[
                                        RulesModel.fields.find((field) => field.value === item.field).label,
                                        RulesModel.operators.find((operator) => operator.value === item.operator).label.toLowerCase(),
                                        '«' + item.value + '»'
                                    ].join(' ')}</td>
                                    <td><TagBadge tag={item.tag}/></td>
                                    <td style={{width: '240px'}}>
                                        <div className="d-flex align-items-center justify-content-end gap-3">
                                            <ButtonWithLoading
                                                className={'btn-light-success text-success btn-circle justify-content-center ' + ((item.is_active) ? ('') : ('d-none'))}
                                                noOriginalIconClassName={true}
                                                iconClassName={'ti ti-refresh fs-5'}
                                                label={''}
                                                loading={processLoading === item.id}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onProcess(item.id)
                                                }}
                                            />
                                            <Link to={'/rules/' + item.id}
                                                  className={'btn btn-light-primary btn-circle d-inline-flex align-items-center justify-content-center'}>
                                                <i className="fs-5 ti ti-edit text-primary"></i>
                                            </Link>
                                            <button type="button"
                                                    className={'btn btn-light-danger btn-circle d-inline-flex align-items-center justify-content-center'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        onDelete(item.id)
                                                    }}>
                                                <i className="fs-5 ti ti-trash text-danger"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <div className="d-flex align-items-center justify-content-end gap-3 mt-3">
                    <ButtonWithLoading
                        className={'btn-light-success text-success justify-content-center'}
                        noOriginalIconClassName={true}
                        iconClassName={'ti ti-refresh fs-5 me-2'}
                        label={'Задіяти всі правила'}
                        loading={processLoading === 'all'}
                        onClick={(e) => {
                            e.preventDefault();
                            onProcessAll()
                        }}
                    />
                </div>
            </SimpleCardContainer>
        </>
);
}