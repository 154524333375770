import React from 'react';
import {useApi} from "../../providers/ApiProvider";
import TagBadge from "../tags/TagBadge";
import TransactionsModel from "../../models/TransactionsModel";


export default function TransactionsListAddTag({transactionId, tags = [], onTagAdded}) {
    const apiContext = useApi();
    const transactionsModel = new TransactionsModel(apiContext.api);


    const addTag = async (e, tag) => {
        e.preventDefault();

        transactionsModel.addTag(transactionId, tag.id, (error) => {
            console.log(error);
        }).then(() => {
            onTagAdded();
        });
    }


    return tags.length ? (
        <div className="btn-group show-on-tr-hover">
            <button className="btn btn-light-success text-success fs-1 btn-sm dropdown-toggle py-0" type="button"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Додати
            </button>
            <ul className="dropdown-menu">
                {tags.map((tag, index) => (
                    <li key={index}>
                        <a href="#" className="dropdown-item" onClick={(e) => addTag(e, tag)}>
                            <TagBadge tag={tag} />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    ) : null;
}