import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import {Outlet} from "react-router-dom";

export default function Tags(props) {
    const breadcrumbs = useBreadcrumbs();


    const [dataItems, setDataItems] = React.useState({});


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Nascho';
    }, []);


    return (
        <>
            <Outlet />
        </>
    );
}