/**
 * Transactions model
 *
 * @param {ApiHelper} api
 */
export default class TransactionsModel {
    constructor(api) {
        this.api = api;
    }


    async list(filter = null, sorting =  null, currentPage = 1, pageSize = 15, errorHandler) {
        let url = '/transactions';
        let query = {
            page: currentPage,
            perPage: pageSize
        };
        if (filter) {
            query.filter = filter;
        }
        if (sorting) {
            query.sort = sorting.field;
            query.direction = sorting.direction;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }

        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async getStats(errorHandler) {
        let url = '/transactions/stats';
        const response = await this.api.getRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async addTag(transactionId, tagId, errorHandler) {
        let url = '/transactions/add-tag';
        const response = await this.api.postRequest(url, {
            transactionId: transactionId,
            tagId: tagId
        });

        return this.processResponse(response, errorHandler);
    }


    async removeTag(transactionId, tag, errorHandler) {
        let url = '/transactions/remove-tag';
        url += '?' + new URLSearchParams({
            transactionId: transactionId,
            tagStyle: tag.style,
            tagName: tag.name
        });

        const response = await this.api.deleteRequest(url);

        return this.processResponse(response, errorHandler);
    }


    async import(file, errorHandler) {
        const url = '/transactions/import';

        const response = await this.api.postRequestFile(url, {
            file: file
        });

        return this.processResponse(response, errorHandler);
    }


    processResponse(response, errorHandler) {
        if (response.status.toString().startsWith('20') && response.data && response.data.success === true && response.data.data) {
            return response.data.data;
        } else {
            if (errorHandler) {
                errorHandler(response.data);
            }
        }

        return false;
    }
}