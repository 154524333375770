import React, {useEffect, useState} from 'react';

import {Link} from "react-router-dom";
import {useApi} from "../../providers/ApiProvider";
import GoogleLogin from "./GoogleLogin";

function Register(props) {

    const apiContext = useApi();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault();

        if (name === '') {
            setError('Please, enter your name.');
            return;
        }

        if (email === '') {
            setError('Please, enter your email.');
            return;
        }

        if (password === '' || passwordConfirm === '') {
            setError('Please, enter your password.');
            return;
        }

        if (password !== passwordConfirm) {
            setError('Passwords do not match.');
            return;
        }

        const credentials = {
            email: email,
            name: name,
            password: password
        }

        apiContext.api.authRegister(credentials).then((response) => {
            if (response === false) {
                setError('Something went wrong.');
            } else if (response.data && response.data.validation_error) {
                let errorText = '';
                Object.entries(response.data.validation_error).forEach(([key, value]) => {
                    errorText = value;
                });
                setError(errorText);
            } else {
                setSuccess(true);

                setTimeout(() => {
                    window.location = '/auth/login';
                }, 5000);
            }
        });
    }


    useEffect(() => {
        document.title = 'Register a new Account | Nascho';
    }, []);


    return (
        <>
            <h4 className="mb-0">Register a new Account</h4>

            {success && (
                <div className="alert alert-primary mt-4" role="alert">Account created successfully! We'll redirect you to the login page shortly.</div>
            ) || !success && (<>
                <small>Please, fill in the form to complete registration.</small>

                {error !== '' && <div className="alert alert-danger mt-4" role="alert">{error}</div>}

                <form className="mb-3 mt-4">
                    <div className="mb-4">
                        <label htmlFor="split-login-name" className="form-label">Your Name</label>
                        <input type="text" className="form-control" id="split-login-name" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>


                    <div className="mb-4">
                        <label htmlFor="split-login-email" className="form-label">E-mail</label>
                        <input type="text" className="form-control" id="split-login-email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="split-login-password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="split-login-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="split-login-password-confirm" className="form-label">Password Confirm</label>
                        <input type="password" className="form-control" id="split-login-password-confirm" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                    </div>

                    <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit" onClick={handleSubmit}>Create an Account</button>
                </form>

                <div className="position-relative text-center my-4">
                    <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">or</p>
                    <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
                </div>

                <div className="row mb-4">
                    <div className="col-12 mb-2 mb-sm-0">
                        <GoogleLogin />
                    </div>
                </div>
            </>)}

            <Link className="fs--1 text-600" to="/auth/login"><span className="d-inline-block me-1">&larr;</span> Go back to login page</Link>
        </>
    );
}

export default Register;
