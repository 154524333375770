import React, {useCallback, useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useParams} from "react-router-dom";
import {useDropzone} from "react-dropzone";
import {useApi} from "../../providers/ApiProvider";
import TransactionsModel from "../../models/TransactionsModel";
import TransactionsAddFile from "./TransactionsAddFile";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";

export default function TransactionsImport(props) {
    const params = useParams();
    const apiContext = useApi();
    const transactionsModel = new TransactionsModel(apiContext.api);
    const breadcrumbs = useBreadcrumbs();
    const onDrop = useCallback(acceptedFiles => {
        setFilesToUpload(acceptedFiles);
        onUploadClick();
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const [selectedCategory, setSelectedCategory] = React.useState('ADDITIONAL');
    const [uploadRunning, setUploadRunning] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = React.useState([]);
    const [fileUploadStatuses, setFileUploadStatuses] = React.useState({});
    const [uploadComplete, setUploadComplete] = React.useState(false);


    const onUploadClick = () => {
        let statuses = {};
        for (let i = 0; i < filesToUpload.length; i++) {
            statuses[i] = 'pending';
        }
        setFileUploadStatuses(statuses);

        setUploadRunning(true);
    }


    const onPerformUpload = (index, status) => {
        setFileUploadStatuses(prevStatus => ({
            ...prevStatus,
            [index]: status
        }));
    }


    useEffect(() => {
        breadcrumbs.update({
            title: 'Імпорт транзакцій',
            breadcrumbs: [
            ]
        });

        document.title = 'Імпорт транзакцій | Nascho';
    }, []);


    useEffect(() => {
        let statuses = Object.values(fileUploadStatuses);
        if (statuses.length === 0) {return;}

        if (statuses.indexOf('pending') === -1 && statuses.indexOf(false) === -1) {
            setUploadComplete(true);
        }

        if (statuses.indexOf('pending') === -1) {
            setUploadRunning(false);
        }
    }, [fileUploadStatuses]);


    return (
        <>
            <SimpleCardContainer
                title={'Імпорт транзакцій'}
                loading={false}
            >
                <p>XLS файл експорту з Приват24.</p>
                <div className="row">
                    <div className="col-lg-6">
                        <div {...getRootProps()} className={'drop-area' + ((isDragActive) ? ' active' : '')}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p className="m-0">Drop the files here...</p> :
                                    <p className="m-0">Drag'n'drop some files here, or click to select files</p>
                            }
                        </div>

                        <div className="mt-3">
                            {filesToUpload && filesToUpload.map((file, index) => (
                                <TransactionsAddFile
                                    key={index}
                                    file={file}
                                    selectedCategory={selectedCategory}
                                    performUpload={uploadRunning}
                                    onPerformUpload={(status) => {
                                        onPerformUpload(index, status);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </SimpleCardContainer>
        </>
    );
}