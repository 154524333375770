import imgCaret from './../../../assets/img/caret.svg';
import chroma from 'chroma-js';

const SelectDot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    '&:before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        marginTop: 2,
        height: 9,
        width: 9,
    },
});

const SelectStyles = {
    clearIndicator: (baseStyles, state) => ({
        ...baseStyles,
        //color: state.isFocused ? 'grey' : 'red',
    }),
    container: (baseStyles, state) => ({
        ...baseStyles,
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        padding: '0',
        fontSize: '.875rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#5a6a85',
        backgroundColor: 'transparent',
        backgroundClip: 'padding-box',
        border: state.isFocused ? 'var(--bs-border-width) solid #aec3ff' : 'var(--bs-border-width) solid #dfe5ef',
        appearance: 'none',
        borderRadius: '7px',
        boxShadow: 'unset',
        transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',

        height: '40px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
    }),
    group: (baseStyles, state) => ({
        ...baseStyles,
    }),
    groupHeading: (baseStyles, state) => ({
        ...baseStyles,
    }),
    indicatorsContainer: (baseStyles, state) => ({
        ...baseStyles,
        backgroundImage: `url(${imgCaret})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 10px center',
        backgroundSize: '13px 8px',
        width: '32px',
        ' > *': {
            display: 'none !important'
        }
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: 'none'
    }),
    loadingIndicator: (baseStyles, state) => ({
        ...baseStyles,
    }),
    loadingMessage: (baseStyles, state) => ({
        ...baseStyles,
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        margin: '-1px 0 0 0',
        padding: 0,
        border: 0,
        boxShadow: 'none',
        borderRadius: 0,
        maxHeight: '200px',
    }),
    menuList: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
        border: '1px #276EFA solid',
        maxHeight: '200px',
    }),
    menuPortal: (baseStyles, state) => ({
        ...baseStyles,
    }),
    multiValue: (baseStyles, state) => ({
        ...baseStyles,
    }),
    multiValueLabel: (baseStyles, state) => ({
        ...baseStyles,
    }),
    multiValueRemove: (baseStyles, state) => ({
        ...baseStyles,
    }),
    noOptionsMessage: (baseStyles, state) => ({
        ...baseStyles,
    }),
    option: (baseStyles, { data, isDisabled, isFocused, isSelected }) => {

        const color = chroma(data.color);
        return {
            ...baseStyles,
            ...SelectDot(data.color),
            padding: '0 10px',
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            height: '40px',
            width: '100%',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '40px',
            ':active': {
                ...baseStyles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? data.color
                        : color.alpha(0.3).css()
                    : undefined,
            },
            /*'&:hover': {
                backgroundColor: '#F9FAFB',
                color: '#0F2154',
                cursor: 'pointer'
            }*/
        };
    },
    input: (styles) => ({ ...styles, ...SelectDot() }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        margin: 0,
        padding: '0',
        lineHeight: '36px;',
        ...SelectDot('#ccc')
    }),
    singleValue: (baseStyles, { data }) => ({ ...baseStyles, ...SelectDot(data.color) }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: '0 20px 0 10px'
    })
}

export {SelectStyles, SelectDot};