import React, {useEffect} from 'react';

export default function RulesSearchForm(props) {
    const [searchText, setSearchText] = React.useState('');


    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            setSearchText(search);
        }
    }, []);


    const onSearch = (e) => {
        e.preventDefault();

        props.onSearch(searchText);
    }


    return (
        <form className={'form-group d-flex flex-row align-items-center justify-content-end gap-3'} onSubmit={onSearch}>
            <input id={'u-l-search'} className={'form-control w-25'} autoComplete={'off'} placeholder={'Введіть текст...'} value={searchText} onChange={(e) => {
                setSearchText(e.target.value)
            }}/>
            <button className="btn btn-primary" onClick={onSearch}>Шукати</button>
        </form>
    );
}
