import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";

import 'leaflet/dist/leaflet.css';

import imgPreloader from "modernize-essentials/k.img/preloader.png";
import {ToastContainer} from "react-toastify";

const App = (props) => {
    const [preloaderActive, setPreloaderActive] = React.useState(true);

    useEffect(() => {
        setPreloaderActive(false);
    }, []);


    return (<>
        <div className={'preloader'+(!preloaderActive ? ' hide' : '')}>
            <img src={imgPreloader} alt="loader" className="lds-ripple img-fluid"/>
        </div>

        <div className="page-wrapper" id="main-wrapper" data-layout="horizontal" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
            <Outlet/>
        </div>
        <ToastContainer />
    </>);
}

export default App;
