import React, {useEffect} from 'react';

import MainLayout from "../layout/MainLayout";
import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";

import imgProfile1 from 'modernize-essentials/dist/images/profile/user-1.jpg';
import imgProfile2 from 'modernize-essentials/dist/images/profile/user-2.jpg';
import imgProfile3 from 'modernize-essentials/dist/images/profile/user-3.jpg';

function Faq(props) {

    const breadcrumbs = useBreadcrumbs();


    useEffect(() => {
        breadcrumbs.update({
            title: null,
            breadcrumbs: []
        });

        document.title = 'Frequently asked questions | Nascho';
    }, []);


    const faqData = [
        {
            title: 'What is the purpose of this service?',
            content: 'Our service automates the process of responding to Google My Business Reviews. This helps you save time, maintain a consistent brand voice, and engage with your customers more effectively.'
        },
        {
            title: 'How do I set up automated responses?',
            content: 'Setting up is easy! Log in to your dashboard, connect your Google My Business account, and then configure your response templates. Our system will automatically reply to new reviews based on the templates you\'ve set.'
        },
        {
            title: 'Is it safe to connect my Google My Business account?',
            content: 'Absolutely. We use OAuth 2.0 for secure authentication and never store your Google My Business credentials. Your data is encrypted and securely stored.'
        },
        {
            title: 'Can I customize the automatic responses?',
            content: 'Yes, you can! Our platform allows you to create custom templates for different types of reviews. You can also include variables like the customer\'s name to personalize responses.'
        },
        {
            title: 'What happens if I receive a negative review?',
            content: 'Our system can identify the sentiment of a review. For negative reviews, you can choose to either send a pre-configured response or get notified to handle it personally.'
        }
    ];


    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="text-center mb-7">
                        <h3 className="fw-semibold">Frequently asked questions</h3>
                        <p className="fw-normal mb-0 fs-4">Get to know more about your business with Nascho</p>
                    </div>
                    <div className="accordion accordion-flush mb-5 card position-relative overflow-hidden" id="accordionFlushExample">
                        {faqData.map((faq, index) => {
                            return (
                                <div className="accordion-item" key={index}>
                                    <h2 className="accordion-header" id={'faq-t-'+index}>
                                        <button className="accordion-button collapsed fs-4 fw-semibold" type="button" data-bs-toggle="collapse"
                                                data-bs-target={'#faq-'+index} aria-expanded="false" aria-controls={'faq-'+index}>
                                            {faq.title}
                                        </button>
                                    </h2>
                                    <div id={'faq-'+index} className="accordion-collapse collapse" aria-labelledby={'faq-t-'+index}
                                         data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body fw-normal">
                                            {faq.content}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="card bg-light-info rounded-2">
                <div className="card-body text-center">
                    <div className="d-flex align-items-center justify-content-center mb-4 pt-8">
                        <a href="#">
                            <img src={imgProfile1} className="rounded-circle me-n2 card-hover border border-2 border-white" width="44"
                                 height="44" />
                        </a>
                        <a href="#">
                            <img src={imgProfile2} className="rounded-circle me-n2 card-hover border border-2 border-white" width="44"
                                 height="44" />
                        </a>
                        <a href="#">
                            <img src={imgProfile3} className="rounded-circle me-n2 card-hover border border-2 border-white" width="44"
                                 height="44" />
                        </a>
                    </div>
                    <h3 className="fw-semibold">Still have questions</h3>
                    <p className="fw-normal mb-4 fs-4">Can't find the answer your're looking for ? Please chat to our friendly team.</p>
                    <a href="#" className="btn btn-primary mb-8">Chat with us</a>
                </div>
            </div>
        </>
);
}

export default Faq;
