import React, {useEffect} from 'react';

import SimpleCardContainer from "../ui/SimpleCardContainer";
import Select from "react-select";
import {useApi} from "../../providers/ApiProvider";
import {Link, Navigate, useParams} from "react-router-dom";
import ButtonWithLoading from "../layout/ui/ButtonWithLoading";
import {useNotifications} from "../../providers/NotificationsProvider";
import TagsModel from "../../models/TagsModel";
import FormCheckbox from "../layout/ui/FormCheckbox";
import {SelectStyles} from "../layout/ui/SelectStyles";

export default function TagsEdit(props) {
    const params = useParams();
    const apiContext = useApi();
    const tagsModel = new TagsModel(apiContext.api);
    const notificationsContext = useNotifications();

    const [loading, setLoading] = React.useState(true);
    const [errors, setErrors] = React.useState([]);
    const [formName, setFormName] = React.useState('');
    const [formStyle, setFormStyle] = React.useState('');
    const [formIsActive, setFormIsActive] = React.useState(true);


    useEffect(() => {
        document.title = 'Додати новий тег | Nascho';

        tagsModel.view(params.tagId).then((response) => {
            if (response !== false) {
                setFormName(response.name);
                setFormStyle(response.style);
                setFormIsActive(response.is_active);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, []);


    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        tagsModel.update(params.tagId,{
            name: formName,
            style: formStyle,
            is_active: formIsActive
        }, onUpdateError).then((response) => {
            if (response !== false) {
                notificationsContext.notify('Тег оновлено!', 'success');
            }

            setLoading(false);
        });
    }


    const onUpdateError = (response) => {
        if (response && response.data) {
            setErrors(response.data);
        }

        setLoading(false);
    }


    const onStyleChange = (selectedOption) => {
        if (!selectedOption) {
            return;
        }

        if (selectedOption.value !== formStyle) {
            setFormStyle(selectedOption.value);
        }
    }


    return (<>
        <div className="d-flex m-block-gap">
            <Link to={'/tags'} className="btn btn-light-info text-info">
                <i className="ti ti-arrow-left me-2"></i>
                Повернутися до списку тегів
            </Link>
        </div>

        <SimpleCardContainer title={'Редагувати тег «' + formName + '»'} loading={loading}>
            <form className={'py-2'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'row mb-3'}>
                            <div className={'col-md-7'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Назва</label>

                                    <input id={'cd-id'} className={'form-control'} value={formName} onChange={(e) => {
                                        setFormName(e.target.value)
                                    }}/>

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.name && <div className="form-text text-danger">{errors.name.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-5'}>
                                <div className={'form-group'}>
                                    <label className="form-label fw-semibold mb-2">Стиль</label>

                                    <Select
                                        name="style"
                                        classNamePrefix="ps-"
                                        value={TagsModel.styles[formStyle]}
                                        //placeholder={'Оберіть стиль...'}
                                        options={Object.values(TagsModel.styles)}
                                        onChange={onStyleChange}
                                        isSearchable={false}
                                        isMulti={false}
                                        styles={SelectStyles}
                                    />

                                    <div className="d-flex justify-content-between">
                                        {errors && errors.name && <div className="form-text text-danger">{errors.name.join(' ')}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <FormCheckbox
                            label={'Активний'}
                            name={'is_active'}
                            onChange={setFormIsActive}
                            value={formIsActive}
                        />

                    </div>
                </div>

                <div className={'form-group mt-3'}>
                    <ButtonWithLoading
                        className={'btn-primary'}
                        noOriginalIconClassName={true}
                        iconClassName={'ti ti-device-floppy fs-5 me-2'}
                        label={'Зберегти зміни'}
                        loading={loading}
                        onClick={onSubmit}
                    />
                </div>
            </form>
        </SimpleCardContainer>
    </>);
}
