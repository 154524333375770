import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import CategoriesModel from "../../models/CategoriesModel";
import {Link} from "react-router-dom";

export default function CategoriesList(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const categoriesModel = new CategoriesModel(apiContext.api);


    const [data, setData] = React.useState({});
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Мої категорії | Nascho';

        performUpload();
    }, []);


    const performUpload = () => {
        setLoading(true);

        categoriesModel.list(uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response);
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Помилка при завантаженні', 'error');
        }
    }


    return (
        <>
            <SimpleCardContainer
                title={'Мої категорії'}
                loading={loading}
            >
                <div className="row">
                    {Object.keys(data) && Object.keys(data).map((type, tIndex) => {
                        return (
                            <div className="col-lg-4" key={tIndex}>
                                {(type === 'income' && (
                                    <h4><i className="ti ti-arrow-up text-success fs-5 me-2"></i> Дохід</h4>
                                )) || (type === 'expense' && (
                                    <h4><i className="ti ti-arrow-down text-danger fs-5 me-2"></i> Витрати</h4>
                                )) || (['income', 'expense'].indexOf(type) === -1 && (
                                    <h4><i className="ti ti-transfer text-muted fs-5 me-2"></i> Перекази</h4>
                                ))}
                                <div className="treeview mt-3 mb-4">
                                    <ul className="list-group">
                                        {data[type] && data[type].map((item, index) => {
                                            return (
                                                <li className="list-group-item node-treeview1 d-flex justify-content-between" key={tIndex + '|' + index}>
                                                    <div>
                                                        <span className="icon expand-icon ti ti-minus me-1"></span>
                                                        <span
                                                            className={'icon node-icon ti me-1 ' + ((item.icon) ? ('ti-' + item.icon) : ('ti-folder'))}></span>
                                                        {item.title}
                                                    </div>
                                                    <div className="d-flex gap-3">
                                                        <Link to={'/t?filter=' + item.title} className="text-muted"><i className="ti ti-filter fs-4"></i></Link>
                                                        <Link to={'/categories/' + item.id + '/edit'} className="text-muted"><i
                                                            className="ti ti-pencil fs-4"></i></Link>
                                                        <Link to={'/categories/' + item.id + '/delete'} className="text-danger"><i
                                                            className="ti ti-trash fs-4"></i></Link>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </SimpleCardContainer>
        </>
    );
}