import React, {useEffect} from 'react';

import {useBreadcrumbs} from "../../providers/BreadcrumbsProvider";
import SimpleCardContainer from "../ui/SimpleCardContainer";
import TransactionsAddFile from "./TransactionsAddFile";
import {useNotifications} from "../../providers/NotificationsProvider";
import {useApi} from "../../providers/ApiProvider";
import TransactionsModel from "../../models/TransactionsModel";
import TransactionsSearchForm from "./TransactionsSearchForm";
import TagBadge from "../tags/TagBadge";
import Pagination from "../ui/Pagination";
import TagsModel from "../../models/TagsModel";
import TransactionsListAddTag from "./TransactionsListAddTag";

export default function TransactionsList(props) {
    const breadcrumbs = useBreadcrumbs();
    const notifications = useNotifications();

    const apiContext = useApi();
    const transactionsModel = new TransactionsModel(apiContext.api);
    const tagsModel = new TagsModel(apiContext.api);

    const [tags, setTags] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [statsData, setStatsData] = React.useState({
        income: {
            total: 0,
            withTags: 0,
            totalSum: 0,
            withTagsSum: 0,
        },
        expense: {
            total: 0,
            withTags: 0,
            totalSum: 0,
            withTagsSum: 0,
        },
        transfer: {
            total: 0,
            withTags: 0,
            totalSum: 0,
            withTagsSum: 0,
        }
    });
    const [filter, setFilter] = React.useState({
        searchText: null,
        sorting: {field: 'date', direction: 'desc'},
        currentPage: 1,
        pageSize: 50
    });
    const [totalPages, setTotalPages] = React.useState(1);
    const [loading, setLoading] = React.useState(true);


    const loadData = () => {
        setLoading(true);

        transactionsModel.list(filter.searchText, filter.sorting, filter.currentPage, filter.pageSize, uploadErrorHandler).then((response) => {
            if (response !== false) {
                setData(response.transactions);

                setTotalPages(Math.ceil(response.total / filter.pageSize));
            }
        }).finally(() => {
            setLoading(false);
        });
    }


    const loadStats = () => {
        transactionsModel.getStats().then((response) => {
            if (response !== false) {
                setStatsData(response);
            }
        });
    }


    const loadTags = () => {
        tagsModel.listPlain().then((response) => {
            if (response !== false) {
                setTags(response);
            }
        });
    }


    const uploadErrorHandler = (response) => {
        if (response.data && response.data[0].error) {
            notifications.notify('Помилка при завантаженні', 'error');
        }
    }


    useEffect(() => {
        breadcrumbs.update({
            title: ''
        });

        document.title = 'Транзакції | Nascho';

        let newFilter = {...filter};
        let urlParams = new URLSearchParams(window.location.search);
        let search = urlParams.get('filter');
        if (search) {
            newFilter.searchText = search;
        }

        let sort = urlParams.get('sort');
        let direction = urlParams.get('direction');
        if (sort && direction) {
            newFilter.sorting = {field: sort, direction: direction};
        }

        let page = urlParams.get('page');
        if (page > 1) {
            newFilter.currentPage = page;
        }
        let pageSize = urlParams.get('pageSize');
        if (pageSize) {
            newFilter.pageSize = pageSize;
        }

        if (!search && !sort && !direction && !page && !pageSize) {
            loadData();
        } else {
            setFilter(newFilter);
        }

        loadStats();
        loadTags();
    }, []);


    useEffect(() => {
        loadData();
        updateUrl();
    }, [filter]);


    const updateUrl = () => {
        let url = '/t';
        let query = {};
        if (filter.searchText) {
            query.filter = filter.searchText;
        }
        if (filter.sorting.field && filter.sorting.direction) {
            query.sort = filter.sorting.field;
            query.direction = filter.sorting.direction;
        }
        if (filter.currentPage) {
            query.page = filter.currentPage;
        }
        if (filter.pageSize) {
            query.pageSize = filter.pageSize;
        }
        if (Object.keys(query).length) {
            url += '?' + new URLSearchParams(query).toString();
        }
        window.history.pushState({}, '', url);
    }


    const onSearch = (searchText) => {
        let newFilter = {...filter};
        newFilter.searchText = searchText;
        setFilter(newFilter);
    }


    const onSort = (e) => {
        let newFilter = {...filter};
        let sortField = e.target.getAttribute('data-sort');
        let sortDirection = 'asc';

        if (e.target.classList.contains('asc')) {
            e.target.classList.remove('asc');
            e.target.classList.add('desc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-ascending');
                e.target.querySelector('i.ti').classList.add('ti-sort-descending');
            }

            sortDirection = 'desc';
        } else {
            e.target.classList.remove('desc');
            e.target.classList.add('asc');

            if (e.target.querySelector('i.ti')) {
                e.target.querySelector('i.ti').classList.remove('ti-sort-descending');
                e.target.querySelector('i.ti').classList.add('ti-sort-ascending');
            }
        }

        newFilter.sorting = {field: sortField, direction: sortDirection};
        setFilter(newFilter);
    }


    const getIconByType = (type) => {
        if (type === 'income') {
            return 'ti-arrow-up text-success';
        } else if (type === 'expense') {
            return 'ti-arrow-down text-danger';
        } else {
            return 'ti-transfer text-muted';
        }
    }


    const formatOutputMoney = (value) => {
        return new Intl.NumberFormat('uk-UA', {style: 'currency', currency: 'UAH'}).format(value);
    }


    const removeTag = (e, transactionId, tag) => {
        e.preventDefault();

        transactionsModel.removeTag(transactionId, tag, (error) => {
            console.log(error);
        }).then(() => {
            loadData();
            loadStats();
        });
    }


    return (
        <>
            <SimpleCardContainer
                title={'Список транзакцій'}
                loading={loading}
            >
                <div className={'pt-2 pb-4'}>
                    <TransactionsSearchForm onSearch={onSearch}/>
                </div>


                <p>Статистика оброблених транзакцій до загальної кількості.</p>
                <div className="row mb-4">
                    <div className="col-lg-4">
                        <h5 onClick={() => onSearch('дохід')} style={{cursor: 'pointer'}}>Дохід, {Math.round(statsData.income.withTags / statsData.income.total * 100)}%</h5>

                        <div className="progress bg-light mb-3 mt-2">
                            <div className="progress-bar bg-success" role="progressbar"
                                 style={{width: `${Math.round(statsData.income.withTags / statsData.income.total * 100)}%`}}
                                 aria-valuenow={Math.round(statsData.income.withTags / statsData.income.total * 100)} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        {statsData.income.total ? (
                            <div>За
                                кількістю: {statsData.income.withTags} / {statsData.income.total}</div>
                        ) : (
                            <div>За кількістю: немає даних</div>
                        )}
                        {statsData.income.totalSum ? (
                            <div>За сумою,
                                грн: {formatOutputMoney(statsData.income.withTagsSum)} / {formatOutputMoney(statsData.income.totalSum)} ({formatOutputMoney(statsData.income.withTagsSum - statsData.income.totalSum)})</div>
                        ) : (
                            <div>За сумою, грн: немає даних</div>
                        )}
                    </div>
                    <div className="col-lg-4">
                        <h5 onClick={() => onSearch('розхід')} style={{cursor: 'pointer'}}>Розхід, {Math.round(statsData.expense.withTags / statsData.expense.total * 100)}%</h5>

                        <div className="progress bg-light mb-3 mt-2">
                            <div className="progress-bar bg-danger" role="progressbar"
                                    style={{width: `${Math.round(statsData.expense.withTags / statsData.expense.total * 100)}%`}}
                                    aria-valuenow={Math.round(statsData.expense.withTags / statsData.expense.total * 100)} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        {statsData.expense.total ? (
                            <div>За
                                кількістю: {statsData.expense.withTags} / {statsData.expense.total}</div>
                        ) : (
                            <div>За кількістю: немає даних</div>
                        )}
                        {statsData.expense.totalSum ? (
                            <div>За сумою, грн: {formatOutputMoney(statsData.expense.withTagsSum)} / {formatOutputMoney(statsData.expense.totalSum)} ({formatOutputMoney(statsData.expense.withTagsSum - statsData.expense.totalSum)})</div>
                        ) : (
                            <div>За сумою, грн: немає даних</div>
                        )}
                    </div>
                    <div className="col-lg-4">
                        <h5 onClick={() => onSearch('переказ')} style={{cursor: 'pointer'}}>Переказ, {Math.round(statsData.transfer.withTags / statsData.transfer.total * 100)}%</h5>

                        <div className="progress bg-light mb-3 mt-2">
                            <div className="progress-bar bg-muted" role="progressbar"
                                    style={{width: `${Math.round(statsData.transfer.withTags / statsData.transfer.total * 100)}%`}}
                                    aria-valuenow={Math.round(statsData.transfer.withTags / statsData.transfer.total * 100)} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        {statsData.transfer.total ? (
                            <div>За
                                кількістю: {statsData.transfer.withTags} / {statsData.transfer.total}</div>
                        ) : (
                            <div>За кількістю: немає даних</div>
                        )}
                        {statsData.transfer.totalSum ? (
                            <div>За сумою, грн: {formatOutputMoney(statsData.transfer.withTagsSum)} / {formatOutputMoney(statsData.transfer.totalSum)} ({formatOutputMoney(statsData.transfer.withTagsSum - statsData.transfer.totalSum)})</div>
                        ) : (
                            <div>За сумою, грн: немає даних</div>
                        )}
                    </div>
                </div>


                <div className="table-responsive rounded-2">
                    <table className="table border customize-table mb-0 align-middle">
                        <thead className="text-dark fs-4">
                        <tr>
                            <th style={{width: '50px'}}></th>
                            <th style={{width: '240px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'date') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="date" onClick={onSort}>
                                    Дата
                                    {(filter.sorting.field === 'date') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'date') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th style={{width: '200px'}}>
                                <h6 className={'fs-4 fw-semibold mb-0 table-heading-sortable ' + ((filter.sorting.field === 'paid') ? (((filter.sorting.direction === 'desc') ? (' desc') : (' asc'))) : (''))}
                                    data-sort="paid" onClick={onSort}>
                                    Сума
                                    {(filter.sorting.field === 'paid') && (filter.sorting.direction === 'asc') && (
                                        <i className={'ti ti-sort-ascending fs-5'}></i>
                                    )}
                                    {(filter.sorting.field === 'paid') && (filter.sorting.direction === 'desc') && (
                                        <i className={'ti ti-sort-descending fs-5'}></i>
                                    )}
                                </h6>
                            </th>
                            <th style={{width: '15%'}}><h6 className="fs-4 fw-semibold mb-0">Акаунт</h6></th>
                            <th><h6 className="fs-4 fw-semibold mb-0">Інформація</h6></th>
                            <th style={{width: '15%'}}><h6 className="fs-4 fw-semibold mb-0">Категорія</h6></th>
                            <th style={{width: '20%'}}><h6 className="fs-4 fw-semibold mb-0">Мітки</h6></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td><i className={'ti fs-4 ' + getIconByType(item.type)}></i>
                                    </td>
                                    <td>{(new Date(item.created_at)).toLocaleString()}</td>
                                    <td>{item.paid}</td>
                                    <td>{item.account}</td>
                                    <td>{item.description}</td>
                                    <td>{item.category}</td>
                                    <td>
                                        <div className="align-items-center d-flex gap-3 justify-content-start text-nowrap">
                                            {item.tags ? Object.keys(item.tags).map((tagColor, index) => (
                                                <div className="d-flex gap-2" key={index}>
                                                    <TagBadge tag={{style: tagColor, name: item.tags[tagColor]}}/>

                                                    <button className="btn btn-light-danger btn-sm p-0 px-1 text-danger" onClick={(e) => removeTag(e, item.id, {style: tagColor, name: item.tags[tagColor]})}>
                                                        <i className="ti ti-x fs-2"></i>
                                                    </button>
                                                </div>
                                            )) : ''}

                                            <TransactionsListAddTag
                                                transactionId={item.id}
                                                tags={tags}
                                                onTagAdded={() => {
                                                    loadData();
                                                    loadStats();
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <div className="my-3">
                    <Pagination
                        currentPage={filter.currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => {
                            setFilter({...filter, currentPage: page});
                        }}
                    />
                </div>
            </SimpleCardContainer>
        </>
    );
}